import { useState, useEffect } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Router from 'next/router';
import { Row, Col } from 'react-bootstrap';
import store from 'store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utils
import { lereroFetch } from 'utils/fetch';

export default function Login() {
  const [success, setSuccess] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [ready, setReady] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Check if user already has a access token, auto redirect to /dashboard
  useEffect(() => {
    const lerero_lms = store.get('lerero_lms');
    if (lerero_lms && lerero_lms.accessToken) {

      if(lerero_lms.user.name == null) {
        if(lerero_lms.user.roleType === 'learner') {
          Router.push({ pathname: '/profile/index_user' });
        } else {
          Router.push({ pathname: '/profile' });
        }
      } else {
        Router.push({ pathname: '/dashboard' });
      }

    } else { 
      setReady(true); 
    }
  }, []);

  const handleLogin = async () => {
    const payload = {
      email: email.toLowerCase(),
      password,
      rememberMe,
    };
    const response = await lereroFetch('POST', `auth/login`, payload);
    if (response.success) {
      store.set('lerero_lms', response.data);
      store.remove('user-management-first-tab-index');
      
      if(response.data.user.name == null) {
        if(response.data.user.roleType === 'learner') {
          Router.push({ pathname: '/profile/index_user' });
          return;
        } else {
          Router.push({ pathname: '/profile' });
          return;
        }
      } 

      if (response.data.user.roleType === 'facilitator' || response.data.user.roleType === 'examiner') Router.push({ pathname: '/course_management' });
      else Router.push({ pathname: '/dashboard' });
    } else {
      const errorMessage =
        response.errors && typeof response.errors !== 'object'
          ? response.errors
          : typeof response.errors === 'object'
          ? response.errors[0].message[0]
          : 'Something went wrong. Please try again later...';

      setSuccess(false);
      toast(
        <ToastNotification
          success={false}
          title={'Error'}
          message={errorMessage}
        />
      );
    }
  };

  const ToastNotification = ({ success, title, message }) => {
    return (
      <Row>
        <Col
          xs={3}
          className="d-flex justify-content-center align-items-center"
        >
          {success ? (
            <img
              src="/global/icon_face_success.svg"
              width="42"
              height="42"
              alt="img"
            />
          ) : (
            <img
              src="/global/icon_face_failed.svg"
              width="42"
              height="42"
              alt="img"
            />
          )}
        </Col>
        <Col xs={9} className={`pt-2 py-2`}>
          <p className="notification-title">{title}</p>
          <p className="notification-sub">{message}</p>
        </Col>
      </Row>
    );
  };

  return (
    ready && (
      <div className="login--container w-100">
        <Head>
          <title>Lerero LMS</title>
        </Head>
        <Row className="w-100 flex-column-reverse flex-lg-row g-0 min-vh-100 justify-content-between justify-content-lg-start">
          <Col xs={12} sm={12} md={12} lg={5} className="pe-0 pe-lg-5">
            <div className="login--image w-100 object-fit h-100">
              <img
                width="100%"
                src="/login/login_icon_v2.png"
                className="d-lg-inline-block d-none sticky-top"
                alt="Background"
              />
              <img
                width="100%"
                src="/login/login_icon_mobile.svg"
                className="d-md-none h-auto"
                alt="Background"
              />
              <img
                width="100%"
                src="/login/login_icon_tablet_2.svg"
                className="d-md-inline-block d-lg-none d-none h-auto"
                alt="Background"
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} className="pe-0 pe-lg-5">
            <div className="login--form">
              <div className="login--form-container">
                <img
                  src="/login/logo_lerero_v3.svg"
                  alt="Lerero"
                  className="login--form-logo"
                  style={{ marginBottom: '3rem' }}
                />
                <p className="login--form-title">Log In</p>
                <p className="login--form-sub">Please enter your details.</p>
                <form
                  data-testid="form-submit"
                  onSubmit={(e) => {e.preventDefault();}}
                >
                  <div>
                    <label htmlFor="email">Email Address</label>
                    <input
                      data-testid="input-email"
                      id="email"
                      type="text"
                      placeholder="johndoe@worklearning.org"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="password">Password</label>
                    <div className="login--form-password-container">
                      <input
                        data-testid="input-password"
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <img
                        src={
                          showPassword ? '/login/eye.svg' : '/login/eye-off.svg'
                        }
                        data-testid="button-show-password"
                        className="login--form-password-eye"
                        alt="Visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                    <div className="login--form-checkbox-container d-flex align-items-start justify-content-between">
                      <div className="checkbox-component d-flex align-items-center">
                        <input
                          data-testid="checkbox-remember"
                          id="remember"
                          type="checkbox"
                          checked={rememberMe}
                          onChange={(e) => {
                            setRememberMe(e.target.checked);
                          }}
                        />
                        <label
                          className="login--form-checkbox-label"
                          htmlFor="remember"
                        >
                          Remember Me
                        </label>
                      </div>
                      <div className="d-flex flex-column">
                        <Link href={'/forgot-password'}>Forgot Password?</Link>
                        {/* <Link href={"/activation-link"}>Resend Activation Link</Link> */}
                      </div>
                    </div>
                    <button
                      data-testid="signIn"
                      id="signIn"
                      className="login--form-button mt-4"
                      disabled={!email || !password}
                      onClick={handleLogin}
                    >
                      Sign In
                    </button>
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        fontFamily: 'Titillium Web',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '1.6rem',
                        color: '#066bfb',
                        marginTop: '4rem',
                      }}
                    >
                      <Link href={'/activation-link'}>
                        Resend Activation Link
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              {/* <button className="login--form-sign-up">Sign Up</button> */}
            </div>
          </Col>
        </Row>
        <ToastContainer
          position="top-right"
          closeButton={false}
          pauseOnHover={false}
          className={'login--toaster'}
          progressStyle={
            success ? { background: '#01CB6B' } : { background: '#FF345D' }
          }
        />
      </div>
    )
  );
}
